import { deepFreeze } from '@/services/utils'

export const ROLE_TYPE = deepFreeze({
  CREATOR: {
    name: 'creator',
    displayName: 'Creator',
    description: 'Can view and edit everything within this team.'
  },
  VIEWER: {
    name: 'viewer',
    displayName: 'Viewer',
    description: 'Can only view everything within this team.'
  }
})

export const USER_ROLE = deepFreeze({
  VIEWER: {
    name: 'viewer',
    displayName: 'Viewer'
  },
  MERCHANDISER: {
    name: 'merchandiser',
    displayName: 'Merchandiser'
  },
  LEGACY: {
    name: 'legacy',
    displayName: 'Legacy'
  },
  PRODUCT_DEVELOPER: {
    name: 'product_developer',
    displayName: 'Product Developer'
  },
  FIT_TECH: {
    name: 'fit-tech',
    displayName: 'Fit Technician'
  },
  PVH_IT: {
    name: 'pvh_it',
    displayName: 'PVH IT'
  },
  CREATOR: {
    name: 'creator',
    displayName: 'Creator'
  },
  DESIGNER: {
    name: 'designer',
    displayName: 'Designer'
  },
  VENDOR: {
    name: 'vendor',
    displayName: 'External Partner'
  },
  EXTERNAL_PARTNER: {
    name: 'external_partner',
    displayName: 'External Partner'
  },
  TECH_DESIGN: {
    name: 'tech_design',
    displayName: 'Tech Design'
  }
})

export const USER_ACTIVITY = deepFreeze({
  ACTIVE: {
    name: 'active',
    label: 'Active',
    value: true
  },
  INACTIVE: {
    name: 'inactive',
    label: 'Inactive',
    value: false
  }
})

export const TEAM_MEMBERSHIP = deepFreeze({
  MEMBER: {
    name: 'member',
    label: 'Member',
    value: false
  },
  TEAM_ADMIN: {
    name: 'team-admin',
    label: 'Team Admin',
    value: true
  }
})
