<template>
  <div
    v-if="permissions.length > 0"
    v-click-outside="closeManager"
    :class="['groups-control', { 'groups-control--plugin': isAppPlugin }]"
  >
    <div
      ref="groupName"
      class="groups-control__active-group"
      @click="toggleManager"
    >
      {{ groupName }}
      <i
        v-if="permissions.length > 1"
        ref="groupsControlArrow"
        :class="['el-icon-arrow-down', { 'is-reverse': showManager }]"
      />
    </div>
    <div
      v-if="showManager && permissions.length > 1"
      ref="groupsControlManager"
      class="groups-control__manager"
    >
      <div class="groups-control__note">
        <ElementInput
          ref="searchInput"
          v-model="searchValue"
          placeholder="Search and select one of the teams you have access to"
          size="small"
          clearable
          class="library-content-header__search"
        >
          <i
            slot="prefix"
            class="el-input__icon el-icon-search"
          />
        </ElementInput>
      </div>
      <div class="groups-control__list">
        <div
          v-for="permission in filteredPermissions"
          :key="permission.group.id"
          ref="groupsControlItem"
          class="groups-control__item"
          @click="setActivePermission(permission)"
        >
          <div class="groups-control__item-name">
            {{ permission.group.name }}
          </div>
          <i class="el-icon-arrow-right" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types'
import { mapActions, mapGetters } from 'vuex'
import { FiltersPersistency } from '@/mixins/FiltersPersistency'
import { FetchData } from '@/mixins/fetchData'
import { FILTER_TYPE } from '@/constants/filterType'
import { LIBRARY_TYPE } from '@/constants/libraryType'
import { ROLE_FLAG } from '@/constants/roleFlag'
import { sortBy } from 'lodash'

export default {
  name: 'GroupsControl',

  mixins: [FiltersPersistency, FetchData],

  props: {
    isAppPlugin: VueTypes.bool.def(false)
  },

  data () {
    return {
      defaultName: 'DesignHub',
      showManager: false,
      searchValue: '',
      libraryTypeGroupFilterMap: {
        [LIBRARY_TYPE.BLOCK]: FILTER_TYPE.DIVISION_ID,
        [LIBRARY_TYPE.TRIM]: FILTER_TYPE.DIVISION_ID,
        [LIBRARY_TYPE.FABRIC]: FILTER_TYPE.DIVISION_ID,
        [LIBRARY_TYPE.STYLE]: FILTER_TYPE.GROUP_ID
      }
    }
  },

  computed: {
    ...mapGetters(['getItems']),

    ...mapGetters({
      activePermission: 'getActivePermission',
      user: 'getCognitoUserData',
      libraryType: 'getActiveLibraryType'
    }),

    /**
     * @returns {Array}
     */
    permissions () {
      const permissions = this.user.permissions
      let sublist

      if (this.isAppPlugin) {
        const pluginRoleFlags = [ROLE_FLAG.VISIBLE_LIBRARY]

        sublist = permissions.filter(permission =>
          pluginRoleFlags.every(
            roleFlag => permission.role_flags[roleFlag] === true
          )
        )
      } else {
        sublist = permissions
      }

      /**
       * @param   {*} object - the object to be sorted
       *
       * @returns            sorted element
       */
      sublist = sortBy(sublist, [
        function (object) {
          return object.group.name
        }
      ])

      return sublist
    },

    /**
     * @returns {Array}
     */
    filteredPermissions () {
      return this.permissions.filter(permission =>
        permission.group.name
          .toLowerCase()
          .includes(this.searchValue.toLowerCase())
      )
    },

    /**
     * @returns {Array}
     */
    items () {
      return this.getItems()
    },

    /**
     * @returns {string}
     */
    groupName () {
      return this.activePermission.group
        ? this.activePermission.group.name
        : this.defaultName
    },

    /**
     * Getting an arbitrary librarytype with group/division filter since all the rest should have the same value.
     *
     * @returns {string}
     */
    leadingLibraryName () {
      return Object.keys(this.libraryTypeGroupFilterMap)[0]
    },

    /**
     * @returns {object}
     */
    activeLibraryFilterGroup () {
      return this.libraryTypeGroupFilterMap[this.libraryType]
    }
  },

  watch: {
    /**
     * @param {object} permission
     */
    activePermission (permission) {
      this.setActiveGroupFilters(permission)
    }
  },

  mounted () {
    const persistedFilters = this.getPersistentFilters(this.leadingLibraryName)
    const groupFilter =
      persistedFilters[FILTER_TYPE.GROUP_ID] ||
      persistedFilters[FILTER_TYPE.DIVISION_ID]

    if (groupFilter) {
      const groupFilterId = groupFilter[0]
      const activePermission = this.permissions.find(
        permission => permission.group.id === groupFilterId
      )

      if (activePermission) {
        this.setActivePermission(activePermission)
      }
    } else {
      this.setActiveGroupFilters(this.activePermission)
    }
  },

  methods: {
    ...mapActions([
      'setAppliedFilter',
      'fetchFilteredItems',
      'resetItems',
      'setActivePermission'
    ]),

    /**
     */
    closeManager () {
      this.showManager = false
    },

    /**
     */
    toggleManager () {
      if (this.permissions.length > 1) {
        this.showManager = !this.showManager
      }
    },

    /**
     * @param {PermissionShape}         permission
     * @param {PermissionGroupShape}    permission.group
     * @param {PermissionRoleShape}     permission.role
     * @param {PermissionRoleFlagShape} permission.role_flags
     */
    setActiveGroupFilters (permission) {
      Object.entries(this.libraryTypeGroupFilterMap).forEach(
        ([libraryType, filterName]) => {
          this.setAppliedFilter({
            filterGroupName: filterName,
            filterIds: [permission.group.id],
            libraryType: libraryType
          })

          this.setAppliedFilterToLocalStorage({
            filterGroupName: filterName,
            value: permission.group.id,
            libraryType: libraryType
          })
        }
      )

      // to refresh data in current page only for libraryTypes that have groups filter
      if (this.activeLibraryFilterGroup) {
        this.buildUrl({
          filterGroupName: this.activeLibraryFilterGroup,
          value: permission.group.id
        })
        this.resetItems()
        this.fetchData(this.items, this.fetchFilteredItems)
      }

      this.closeManager()
    }
  }
}
</script>

<style lang="scss" scoped>
$active-group-icon-font-size: 12px;

.groups-control {
  @include position-absolute(center, center);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.groups-control__active-group {
  display: flex;
  align-items: center;
  color: $blue;
  font-weight: $font-weight-medium;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  transition: color $transition;

  &:hover {
    color: $blue;
  }

  i {
    margin-left: spacing(1/2);
    font-size: $active-group-icon-font-size;
    vertical-align: text-bottom;
    transition: transform $transition-duration;
  }

  .is-reverse {
    transform: rotateZ(180deg);
  }
}

.groups-control__manager {
  position: absolute;
  top: 0;
  width: spacing(56);
  margin-top: spacing(5);
  background: $white;
  border-radius: $border-radius-m;
  box-shadow: $shadow-default;
}

.groups-control__list {
  width: 100%;
  max-height: spacing(38);
  margin-bottom: spacing(1);
  overflow: auto;
}

.groups-control__item-name {
  flex: 1 0 auto;
}

.groups-control__item {
  display: flex;
  align-items: center;
  margin-right: spacing(1);
  margin-left: spacing(1);
  padding: spacing(1) spacing(2);
  border-bottom: $border-divider;
  cursor: pointer;

  &:hover {
    .groups-control__item-name {
      color: $blue;
      font-weight: $font-weight-medium;
    }
  }

  &:last-child {
    border-bottom: $border-divider;
  }
}

.groups-control__note {
  @include text-label;

  padding: spacing(2) spacing(2) spacing(1);
  color: $grey;
}

.groups-control__item-role {
  margin-right: spacing(4);
}

.groups-control--plugin {
  top: 0;
  left: 0;
  padding-left: spacing(2);
  transform: none;

  .groups-control__manager {
    left: 0;
  }

  .groups-control__active-group {
    color: $blue;
  }
}
</style>
